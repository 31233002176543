// выпадающее меню при клике на "Подборки"
const menuDigest = document.getElementById('menu-digest');
// заголовок "Подборки"
const titleDigest = document.getElementById('title-digest');
const menuDigestElements = menuDigest.querySelectorAll('li');
// выпадающее меню при клике на "Темы"
const menuTopic = document.getElementById('menu-topic');
const menuTopicElements = menuTopic.querySelectorAll('li');
// заголовок "Темы"
const titleTopic = document.getElementById('title-topic');

// контент страниц
const mainContent = document.querySelector('main');


for (let element of menuDigestElements) {
    element.addEventListener('click', function(event) {
      event.stopPropagation();
    });
}

for (let element of menuTopicElements) {
  element.addEventListener('click', function(event) {
    event.stopPropagation();
  });
}

// обработчик клика на меню Подборки и Темы
function handleDocumentClick(event) {
  // Проверяем, был ли клик по заголовку "Подборки"
  if (titleDigest.contains(event.target)) {
    toggleDigestMenu();
    closeTopicMenu(); // Закрываем меню Темы
    
    // Проверяем, был ли клик по заголовку "Темы"
  } else if (titleTopic.contains(event.target)) { 
    toggleTopicMenu();
    closeDigestMenu(); // Закрываем меню Подборки
  } else {
    closeDigestMenu(); // Закрываем меню Подборки, если клик вне
    closeTopicMenu(); // Закрываем меню Темы, если клик вне
  }
}

// Закрытие меню Подборки
function closeDigestMenu() {
  menuDigest.classList.remove('open');
  titleDigest.classList.remove('active'); 
  // Устанавливаем видимость контента в зависимости от состояния обоих меню
  updateMainContentVisibility();
}

// Закрытие меню Темы
function closeTopicMenu() {
  menuTopic.classList.remove('open');
  titleTopic.classList.remove('active'); 
  // Устанавливаем видимость контента в зависимости от состояния обоих меню
  updateMainContentVisibility();
}

// Переключение состояния меню Подборки
function toggleDigestMenu() {
  menuDigest.classList.toggle('open'); 
  titleDigest.classList.toggle('active');
  updateMainContentVisibility(); // Обновляем видимость контента
}

// Переключение состояния меню Темы
function toggleTopicMenu() {
  menuTopic.classList.toggle('open'); 
  titleTopic.classList.toggle('active');
  updateMainContentVisibility(); // Обновляем видимость контента
}

// Функция для обновления видимости контента
function updateMainContentVisibility() {
  const isAnyMenuOpen = menuDigest.classList.contains('open') || menuTopic.classList.contains('open');
  mainContent.style.display = isAnyMenuOpen ? 'none' : 'block';
}

// Обработчик клика для всего документа
document.addEventListener('click', handleDocumentClick);